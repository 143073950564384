import React, { useState, useEffect, useCallback } from "react";
import api from "../utils/api";
import logo_print from "../assets/images/logo_print.png";
import { useAuth } from "../utils/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPrint } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DashboardHomePage = () => {
  const [transactions, setTransactions] = useState([]);
  const [expandedDetailsIndex, setExpandedDetailsIndex] = useState(null);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [entryChangePercentage, setEntryChangePercentage] = useState(0);
  const [filter, setFilter] = useState({ date: new Date(), location: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { setLoading } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const videoLotteryKeys = [
    { prefix: "$", suffix: "", label: "In", key: "totalIn" },
    { prefix: "$", suffix: "", label: "Out", key: "totalOut" },
    { prefix: "$", suffix: "", label: "Difference: ", key: "diff" },
    { prefix: "", suffix: "%", label: "Lottery Per Tax: ", key: "lotteryPerTax" },
    { prefix: "$", suffix: "", label: "Lottery Tax:", key: "lotteryTax" },
    { prefix: "$", suffix: "", label: "Lottery Total:", key: "lotteryTotal" },
    { prefix: "", suffix: "%", label: "State Per Tax:", key: "statePerTax" },
    { prefix: "$", suffix: "", label: "State Tax:", key: "stateTax" },
    { prefix: "$", suffix: "", label: "State Total:", key: "stateTotal" },
    { prefix: "$", suffix: "", label: "Operator Share:", key: "operatorShare" },
    { prefix: "$", suffix: "", label: "Merchant Share:", key: "merchantShare" },
    { prefix: "$", suffix: "", label: "VLM:", key: "vlm" },
    { prefix: "$", suffix: "", label: "Merchant Fee:", key: "merchantFee" },
    { prefix: "$", suffix: "", label: "Amount to shop:", key: "amountShop" },
    { prefix: "", suffix: "", label: "Comment:", key: "comment" },
  ];

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async (clear) => {
    try {
      setLoading(true);
      const params = {
        ...(clear !== true && {
          date: getISODate(filter.date),
          location: filter.location,
        }),
        ...(clear === true && {
          date: getISODate(),
        }),
      };
      const response = await api.post("/user/getTransactions", params);

      const transactionsData = response.data.response.transactions || [];
      const summary = response.data.response.summary;
      setTransactions(transactionsData);
      setCurrentPage(1);
      setExpandedDetailsIndex(-1);
      const pages = Math.ceil(response.length / itemsPerPage);
      setTotalPages(pages);
      setLoading(false);
      if (summary) {
        const { totalAmount, amountDiffPercentage, count, countDiffPercentage } = summary;
        setTotalEntries(count);
        setTotalAmount(totalAmount);
        setPercentageChange(amountDiffPercentage);
        setEntryChangePercentage(countDiffPercentage);
      } else {
        setTotalEntries(0);
        setTotalAmount(0);
        setPercentageChange(0);
        setEntryChangePercentage(0);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching transactions:", error);
      if (error.response) {
        switch (error.response.status) {
          case 404:
            console.error("The endpoint could not be found.");
            break;
          case 401:
            console.error("Token might be expired or invalid. Please log in again.");
            break;
          default:
            console.error("An error occurred:", error.response.data.message);
        }
      } else {
        console.error("Network error or unexpected issue:", error.message);
      }
    }
  };

  const getISODate = (date = new Date()) => {
    if (!date) return null;
    return `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}T00:00:00.000Z`;
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setFilter((prev) => ({ ...prev, location: value }));
  };

  useEffect(() => {
    const pages = Math.ceil(transactions.length / itemsPerPage);
    setTotalPages(pages);
  }, [transactions, itemsPerPage]);

  const handleDateChange = (date) => {
    setFilter((prev) => ({ ...prev, date }));
  };

  const clearFilters = () => {
    setFilter({ date: new Date(), location: "" });
    fetchTransactions(true);
  };

  const handleToggleDetails = (index) => {
    setExpandedDetailsIndex(expandedDetailsIndex === index ? null : index);
  };

  const getDate = () => {
    if (!filter.date) return null;
    const { date } = filter;
    return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}/${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const safeToFixed = (value = "", decimalPlaces = 2) => {
    if (typeof value !== "number") return value;
    return value.toFixed(decimalPlaces);
  };

  const getRowSpan = useCallback(
    (currIndex, totalEntries) => {
      const totalKeys = videoLotteryKeys.length;
      if (currIndex + 1 === totalKeys && totalEntries * 2 > totalKeys) return totalEntries * 2 - totalKeys + 1;
      return 1;
    },
    [videoLotteryKeys.length]
  );

  const getElementWithSpace = (val1, val2) => {
    return `<p style="display: flex; justify-content: space-between; align-items: center; margin: 0px;">
      <span>${val1}</span>
      <span>${val2}</span>
    </p>`;
  };

  const getVideoLottery = (printData) => {
    if (!printData.videoLottery?.items?.length) return "";
    let data = [];
    const { items, eItems } = printData.videoLottery;
    const max = items.length > videoLotteryKeys.length / 2 ? items.length : videoLotteryKeys.length / 2;
    for (let i = 0, j = 0; i < max; i++, j += 2) {
      let row = "";
      if (i < items.length && i < videoLotteryKeys.length / 2) {
        row = `<tr>
          <td>${i + 1}</td>
          <td>${getElementWithSpace("In", `$${safeToFixed(items[i].in)}`)}</td>
          <td>E </td>
          <td>${getElementWithSpace("In", `$${safeToFixed(eItems[i].in)}`)}</td>
          <td class="td-maxWidth" rowspan=${getRowSpan(j, items.length)}>
            ${getElementWithSpace(videoLotteryKeys[j].label, `${videoLotteryKeys[j].prefix}${safeToFixed(printData.videoLottery[videoLotteryKeys[j].key])}${videoLotteryKeys[j].suffix}`)} 
          </td>
        </tr>
        <tr> 
          <td></td> 
          <td>${getElementWithSpace("Out", `$${safeToFixed(items[i].out)}`)}</td> 
          <td></td> 
          <td>${getElementWithSpace("Out", `$${safeToFixed(eItems[i].out)}`)}</td>
          ${
            videoLotteryKeys[j + 1]
              ? `<td class="td-maxWidth" rowspan=${getRowSpan(j + 1, items.length)}>
                  ${getElementWithSpace(
                    videoLotteryKeys[j + 1].label,
                    `${videoLotteryKeys[j + 1].prefix}${safeToFixed(printData.videoLottery[videoLotteryKeys[j + 1].key])}${videoLotteryKeys[j + 1].suffix}`
                  )}
              </td>`
              : ""
          }
        </tr>`;
      } else if (i < items.length) {
        row = `<tr>
          <td>${i + 1}</td>
          <td>${getElementWithSpace("In", `$${safeToFixed(items[i].in)}`)}</td>
          <td>E </td>
          <td>${getElementWithSpace("In", `$${safeToFixed(eItems[i].in)}`)}</td>
        </tr>
        <tr> 
          <td></td> 
          <td>${getElementWithSpace("Out", `$${safeToFixed(items[i].out)}`)}</td> 
          <td></td> 
          <td>${getElementWithSpace("Out", `$${safeToFixed(eItems[i].out)}`)}</td> 
        </tr>`;
      } else if (i < videoLotteryKeys.length / 2) {
        row = `<tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="td-maxWidth">
            ${getElementWithSpace(videoLotteryKeys[j].label, `${videoLotteryKeys[j].prefix}${safeToFixed(printData.videoLottery[videoLotteryKeys[j].key])}${videoLotteryKeys[j].suffix}`)}
          </td>
        </tr>
        ${
          videoLotteryKeys[j + 1]
            ? `<tr> 
          <td></td> 
          <td></td> 
          <td></td> 
          <td></td> 
          <td class="td-maxWidth">
            ${getElementWithSpace(
              videoLotteryKeys[j + 1].label,
              `${videoLotteryKeys[j + 1].prefix}${safeToFixed(printData.videoLottery[videoLotteryKeys[j + 1].key])}${videoLotteryKeys[j + 1].suffix}`
            )}
          </td>
        </tr>`
            : ""
        }`;
      }
      data.push(row);
    }
    return data.join("");
  };

  const handlePrint = async (item) => {
    try {
      const response = await api.get(`/user/getTransaction/${item.id}`);
      const printData = response.data.response;
      if (printData) {
        const collectorSign = printData.images?.find((image) => image.s3FilePath.includes("collector"))?.url || null;
        const merchantSign = printData.images?.find((image) => image.s3FilePath.includes("merchant"))?.url || null;
        const printContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Collection Sheet</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      padding: 0px 20px;
                  }
                  .header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      height: 75px;
                  }
                  .logo {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                      margin-left: -80px;
                  }
                  .same-width {
                      width: 33.3%;
                      text-align: center;
                  }
                  .sheet-id {
                      color: red;
                  }
                  .contact-info {
                      text-align: center;
                      margin-bottom: 20px;
                  }
                  .highlight {
                    color: blue;
                    font-weight: bold;
                  }
                  table {
                      border-collapse: collapse;
                      width: 100%;
                      margin-bottom: 20px;
                  }
                  th, td {
                      border: 1px solid black;
                      padding: 8px;
                      text-align: left;
                      font-size: 14px;
                  }
                  .right-align {
                      color: blue;
                      text-align: right;
                  }
                  .td-maxWidth {
                      max-width: 190px;
                      vertical-align: top;
                      word-wrap: break-word;
                  }
                  .justify-between {
                    display: flex;
                    justify-content: space-between;
                  }
                  .address { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
                  .address div { width: 30%; }
                  .address .center { text-align: center; }
                  .info { text-align: center; margin-bottom: 20px; }
                  .transaction{
                    float: inline-end;
                  }
                  .contact {
                    text-align: end;
                  }
                  .date {
                    text-align: start;
                  }
                  .footer {
                    margin: 0px 30px;
                  }
                  .role {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    border-bottom: 2px solid;
                  }
                  .role span {
                    margin: auto;
                  }
                  .role img {
                    max-height: 60px;
                    max-width: fit-content;
                    margin: 5px auto;
                  }
              </style>
          </head>
          <body>
            <div class="header">
              <span class="same-width"><img class="logo" src=${logo_print} alt="company logo" /></span>
              <h2 class="same-width">Collection Sheet</h2>
              <h2 class="sheet-id same-width contact">${printData.collectionSheetId || "N/A"}</h2>
            </div>
          <div class="address">
            <p class="same-width date">3171 W.Dupont Ave.<br/>
              Belle, WV 25015</p>
            <h4 class="same-width">SERVICE 1-866-464-7896</h4>
            <p class="same-width contact">
              OFFICE (304) 926-6411<br>
            </p>
          </div>
          <div class="address highlight">
            <span class="same-width date">Date: ${new Date(printData.createdAt).toLocaleDateString()}</span>
            <span class="same-width">Location: ${printData.location || "N/A"}</span>
            <span class="same-width contact">Amount to Shop: $${safeToFixed(printData.videoLottery?.amountShop)}</span>
          </div>
              <h3>Video Lottery</h3>
              <table>
                  ${getVideoLottery(printData)}
              </table>
              <h3>Amusement</h3>
              <table>
                  ${printData.amusement?.items
                    .map(
                      (item) => `
                    <tr>
                      <td></td>
                      <td>${item.itemName}</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(item.amount)}</td>
                    </tr>
                  `
                    )
                    .join("")}
              </table>
              <table>
                  <tr>
                      <td></td>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(printData.amusement?.totalIn)}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Less 6% Sales Tax</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(printData.amusement?.salesTax)}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Amount to Divide</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(printData.amusement?.amountDivide)}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Operator w/ Tax added</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(printData.amusement?.operatorShare)}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Merchant</td>
                      <td></td>
                      <td></td>
                      <td class="right-align"> $${safeToFixed(printData.amusement?.merchantShare)}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Amount to shop</td>
                      <td></td>
                      <td></td>
                      <td class="right-align">$${safeToFixed(printData.amusement?.amountShop)}</td>
                  </tr>
              </table>
              <br/>
              <div class="footer">
                <div class="justify-between">
                  <div class="role">
                    <span>Collector</span>
                    <img src="${collectorSign}" />
                  </div>
                  <div class="role">
                    <span>Merchant</span>
                    <img src="${merchantSign}" />
                  </div>
                </div>
              </div>
            </body>
          </html>
        `;

        const printWindow = window.open("", "", "height=800,width=1000");
        printWindow.document.open();
        printWindow.document.write(printContent);
        printWindow.document.close();
        const images = printWindow.document.querySelectorAll("img"); // Select all images
        const imagePromises = Array.from(images).map((img) => {
          return new Promise((resolve, reject) => {
            if (img.complete) {
              // Check if image is already loaded
              resolve();
            } else {
              img.onload = resolve;
              img.onerror = reject; // Handle potential errors
            }
          });
        });

        Promise.all(imagePromises)
          .then(() => {
            printWindow.print();
          })
          .catch((error) => {
            console.error("Error loading images:", error);
            printWindow.print(); // Print even if some images fail (user choice)
          });
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);

  const paginationButtons = [];
  for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
    paginationButtons.push(
      <button key={i} onClick={() => handlePageChange(i)} className={`bg-color text-white px-4 py-2 rounded-md mr-2 ${i === currentPage ? "bg-color-dark" : "bg-color-dark-hover"}`}>
        {i}
      </button>
    );
  }

  return (
    <div className="bg-emerald-50 w-full mx-auto min-h-screen flex flex-col">
      <div className="container w-full mx-auto px-4 py-8">
        <div className="filter-section bg-white rounded-lg shadow-md mb-8 p-4">
          <div className="flex flex-wrap items-center justify-center space-x-4">
            <div className="flex flex-col items-start md:flex-row md:items-center md:space-x-2 mb-4 md:mb-0">
              <label htmlFor="dateFilter" className="block text-sm font-medium text-gray-700 md:mr-2">
                Date:
              </label>
              <DatePicker
                id="dateFilter"
                selected={filter.date}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-48"
                placeholderText="dd-MM-yyyy"
              />
            </div>
            <div className="flex flex-col items-start md:flex-row md:items-center md:space-x-2 mb-4 md:mb-0">
              <label htmlFor="cityFilter" className="block text-sm font-medium text-gray-700 md:mr-2">
                Location:
              </label>
              <input
                type="text"
                id="cityFilter"
                value={filter.location}
                placeholder="Enter location"
                onChange={handleLocationChange}
                className="mt-1 block w-full border border-gray-300 p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-48"
              />
            </div>
            <div className="flex items-center justify-center mb-4 md:mb-0">
              <button
                onClick={fetchTransactions}
                className={`bg-color text-white px-4 py-2 rounded-md bg-color-hover transition-colors duration-300 mr-2
                   ${!filter.date && !filter.location ? "cursor-not-allowed opacity-50" : ""}`}
                disabled={!filter.date && !filter.location}>
                Apply Filters
              </button>
              <button
                onClick={clearFilters}
                className={`bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition-colors duration-300  ${!filter.date && !filter.location ? "cursor-not-allowed opacity-50" : ""}`}
                disabled={!filter.date && !filter.location}>
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <div className="summary-section flex flex-wrap justify-around mb-8">
          <div className="summary-block bg-white rounded-lg shadow-md p-4 text-center flex-1 mb-4 md:mb-0 md:mr-4">
            <p className="font-semibold text-gray-800 mb-2">Total Amount</p>
            <p className="text-xl font-bold text-sky-600">${totalAmount.toFixed(2)}</p>
            <div className={`rounded-full border-2 border-sky-400 bg-emerald-50 p-2 inline-block ${percentageChange >= 0 ? "text-green-600" : "text-red-600"}`}>
              {percentageChange >= 0 ? `+${percentageChange.toFixed(2)}%` : `${percentageChange.toFixed(2)}%`} Since yesterday
            </div>
          </div>
          <div className="summary-block bg-white rounded-lg shadow-md p-4 text-center flex-1 mb-4 md:mb-0 md:ml-4">
            <p className="font-semibold text-gray-800 mb-2">Total Entries</p>
            <p className="text-xl font-bold text-sky-600">{totalEntries}</p>
            <div className={`rounded-full border-2 border-sky-400 bg-emerald-50 p-2 inline-block ${entryChangePercentage >= 0 ? "text-green-600" : "text-red-600"}`}>
              {entryChangePercentage >= 0 ? `+${entryChangePercentage.toFixed(2)}%` : `${entryChangePercentage.toFixed(2)}%`} Since yesterday
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="mb-5">{filter.date && <span>Collection on {getDate()}</span>}</div>
          <div className="bg-white rounded-lg shadow-md p-4">
            <table className="min-w-full bg-white rounded-lg shadow-md">
              <thead>
                <tr style={{ textAlignLast: "left" }}>
                  <th className="px-4 py-2">Collection ID</th>
                  <th className="px-4 py-2">Date</th>
                  <th className="px-4 py-2">Location</th>
                  <th className="px-4 py-2">Amount to shop</th>
                  <th className="px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <tr className="hover:bg-gray-100 cursor-pointer">
                      <td className="py-4 px-4 border-b">{item.collectionSheetId}</td>
                      <td className="py-4 px-4 border-b">{item.date.slice(0, 10)}</td>
                      <td className="py-4 px-4 border-b">{item.location || "N/A"}</td>
                      <td className="py-4 px-4 border-b">${item.videoLottery.amountShop.toFixed(2)}</td>
                      <td className="py-4 px-4 border-b">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePrint(item);
                          }}
                          className="ml-4 text-gray-600 hover:text-gray-800">
                          <FontAwesomeIcon icon={faPrint} />
                        </button>
                      </td>
                      <td className="py-4">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleDetails(index);
                          }}
                          className="absolute transform translate-y-1/2 left-1/2 -ml-4 cursor-pointer"
                          style={{ marginTop: "3px" }}>
                          <FontAwesomeIcon icon={expandedDetailsIndex === index ? faChevronUp : faChevronDown} />
                        </span>
                      </td>
                    </tr>
                    {expandedDetailsIndex === index && (
                      <tr>
                        <td colSpan="5" className="py-4 px-4 border-b">
                          <div className="bg-gray-50 p-4 rounded-lg">
                            <h3 className="text-lg font-semibold mb-2">Video Lottery Details</h3>
                            <p>Collection ID: {item.collectionSheetId}</p>
                            <p>Date:{item.date.slice(0, 10)}</p>
                            <p>Location: {item.location}</p>
                            <p>Amount: ${item.videoLottery.amountShop.toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-center mt-4">
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <div className="flex space-x-2">{paginationButtons}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHomePage;
